// @ts-nocheck
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  if (!config.public.clarityKey) return;

  // Taken from the old codebase: https://git.airweb.fr/backend/cards-shop/front/-/blob/develop/modules/clarity/plugin.js?ref_type=heads
  if (typeof window !== "undefined") {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", config.public.clarityKey);
  }
});
