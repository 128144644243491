<script lang="ts" setup>
interface Props {
  extraClasses?: string;
  asLabel?: boolean;
  primary?: boolean;
  bold?: boolean;
  type?: "button" | "submit" | "reset";
  link?: string;
  loading?: boolean;
  shadow?: boolean;
  disabled?: boolean;
  form?: string;
}

const props = withDefaults(defineProps<Props>(), {
  extraClasses: "",
  asLabel: false,
  primary: false,
  bold: true,
  type: "button",
  link: undefined,
  loading: false,
  shadow: false,
  disabled: false,
});

const isLink = computed(() => props.link && !props.disabled);
</script>

<template>
  <NuxtLink
    v-if="isLink"
    :class="[
      {
        'bg-primary text-black': props.primary,
        'font-bold': props.bold,
        'bg-gray-300': !props.primary,
        shadow: props.shadow,
      },
    ]"
    :to="props.link"
    class="cursor-pointer px-4 py-3 text-center uppercase"
  >
    <slot />

    <AppInlineLoader
      v-if="props.loading"
      class="ml-2 inline-block h-6 w-6 fill-current"
    />
  </NuxtLink>

  <label
    v-else-if="props.asLabel"
    :class="[
      {
        'bg-primary text-black': props.primary,
        'font-bold': props.bold,
        'bg-gray-300': !props.primary,
        shadow: props.shadow,
      },
    ]"
    class="cursor-pointer px-4 py-3 text-center uppercase"
  >
    <slot />

    <AppInlineLoader
      v-if="props.loading"
      class="ml-2 inline-block h-6 w-6 fill-current"
    />
  </label>

  <button
    v-else
    :class="[
      {
        'bg-primary text-black': props.primary,
        'font-bold': props.bold,
        'bg-gray-300': !props.primary,
        shadow: props.shadow,
      },
    ]"
    :disabled="props.disabled || props.loading"
    :form="props.form"
    :type="props.type"
    class="cursor-pointer px-4 py-3 text-center uppercase disabled:cursor-not-allowed disabled:opacity-50"
  >
    <slot />

    <AppInlineLoader
      v-if="props.loading"
      class="ml-2 inline-block h-6 w-6 fill-current"
    />
  </button>
</template>
