import { version } from "@/package.json";
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const router = useRouter();

  Sentry.init({
    enabled: Boolean(config.public.sentryEnabled),
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    release: version,
    integrations: [Sentry.browserTracingIntegration({ router })],
    environment: config.public.environment,
    tracesSampleRate: 0.7,
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
