<template>
  <svg viewBox="0 0 20 20">
    <g transform="translate(-849.518 -834.963)">
      <path
        class="a"
        d="M863.674 854.962h-8.314a5.85 5.85 0 01-5.843-5.843v-8.313a5.85 5.85 0 015.843-5.843h8.313a5.85 5.85 0 015.843 5.843v8.313a5.85 5.85 0 01-5.842 5.843zm-8.313-18.113a3.962 3.962 0 00-3.957 3.957v8.313a3.962 3.962 0 003.957 3.957h8.313a3.962 3.962 0 003.957-3.957v-8.313a3.962 3.962 0 00-3.957-3.957z"
      />
      <path
        class="a"
        d="M859.517 850.411a5.448 5.448 0 115.447-5.448 5.454 5.454 0 01-5.447 5.448zm0-9.01a3.562 3.562 0 103.561 3.562 3.566 3.566 0 00-3.561-3.562z"
      />
      <circle
        class="a"
        cx="1.164"
        cy="1.164"
        r="1.164"
        transform="translate(863.652 838.565)"
      />
    </g>
  </svg>
</template>
