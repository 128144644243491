<script lang="ts" setup>
import { set } from "idb-keyval";
import { useTunnel } from "./stores/tunnel";
import { useCart } from "./stores/cart";
import { deepToRaw } from "./utils/deepToRaw";

const isSessionExpired = false;

const cart = useCart();
const tunnel = useTunnel();
const nuxtApp = useNuxtApp();

if (process.client) {
  tunnel.$subscribe(
    (_, state) => {
      const persistedState = deepToRaw(state);

      set("yelo-tunnel", persistedState).catch((error) => {
        nuxtApp.$sentryCaptureException(error);
      });
    },
    { detached: true, deep: true, immediate: false },
  );

  cart.$subscribe(
    (_, state) => {
      const persistedState = deepToRaw(state);

      set("yelo-cart", persistedState).catch((error) => {
        nuxtApp.$sentryCaptureException(error);
      });
    },
    { detached: true, deep: true, immediate: false },
  );
}

useHead({
  bodyAttrs: {
    class: "font-sans",
  },
  link: [
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
    },
    {
      href: "https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap",
      rel: "stylesheet",
    },
    {
      href: "/favicon.png",
      rel: "icon",
      type: "image/png",
    },
  ],
});
</script>

<template>
  <div>
    <AppHeader />

    <NuxtPage />

    <AppFooter />

    <div id="modal"></div>

    <div
      v-if="isSessionExpired"
      class="absolute bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="flex flex-col items-center bg-white p-8">
        <p class="text-center text-lg font-bold uppercase">
          {{ $t("otherslabels.popup_session_timeout") }}
        </p>

        <AppButton class="mt-6 bg-primary" @click="">
          {{ $t("otherslabels.popup_session_timeout_button") }}
        </AppButton>
      </div>
    </div>
  </div>
</template>