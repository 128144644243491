import { AirwebTicketLegacyClient } from "@airweb/ticket-legacy-client";
import { useAuth } from "@/stores/auth";

export default defineNuxtPlugin(async (nuxtApp) => {
  const auth = useAuth();

  const client = new AirwebTicketLegacyClient({
    url: nuxtApp.$config.public.ticketApiUrl,
    signatureKey: nuxtApp.$config.public.hmackey,
  });

  // FIXME: 2030 probably a bad idea
  const expires = new Date("2030-01-01");
  const ticketToken = useCookie("AW_TICKET_TOKEN", { expires });

  if (ticketToken.value && import.meta.server) {
    try {
      const user = await client.account.getUser(ticketToken.value);
      auth.user = user;
    } catch (e) {
      // TODO: Handle session expired?
      console.log("[ERROR] [PLUGIN] [AIRWEB]", JSON.stringify(e));
    }
  }

  return {
    provide: {
      ticket: client,
    },
  };
});
