<script lang="ts" setup>
import { version } from "@/package.json";

const router = useRouter();
const { locale, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();

function changeLocale(event: Event) {
  const target = event.currentTarget as HTMLSelectElement;
  const newPath = switchLocalePath(target.value);

  return router.push(newPath);
}
</script>

<template>
  <footer class="mt-6 text-gray-900 border-t border-gray-200">
    <div
      class="container flex flex-col px-4 py-6 mx-auto sm:grid sm:grid-cols-3 sm:gap-6"
    >
      <div class="order-1 text-center sm:text-left">
        <p>
          <strong>Yélo</strong>
          <br />
          <span>Maison de la Mobilité</span>
        </p>
        <p class="mt-4">
          <a
            :href="`tel:${$t('pages.footer.label_phone')}`"
            class="border-b-2 border-gray-700 border-dotted"
            title="Appeler le service client Yélo"
          >
            <img
              :alt="$t('pages.footer.label_phone')"
              class="w-64 mx-auto sm:mx-0"
              src="@/assets/images/phone_number.png"
            />
          </a>

          <span>{{ $t("pages.footer.label_phone_description") }}</span>
        </p>

        <p class="mt-4">
          <span>Lundi à vendredi : 7h30 - 19h</span>
          <br />
          <span>Samedi : 8h – 12h et 13h30 - 16h10</span>
        </p>
      </div>

      <div
        class="flex items-center justify-center order-3 mt-10 sm:order-2 sm:mt-0 sm:flex-col lg:flex-row"
      >
        <a
          href="https://yelo.agglo-larochelle.fr"
          rel="noopener"
          target="_blank"
        >
          <img
            alt="Yelo Logo"
            class="h-10 mx-4"
            src="@/assets/images/footer_logo_yelo.svg"
          />
        </a>
        <a href="https://www.airweb.fr/" rel="noopener" target="_blank">
          <img
            alt="Airweb Logo"
            class="h-10 mx-4 sm:mt-6 lg:mt-0"
            src="@/assets/images/footer_logo_airweb.svg"
          />
        </a>

        <span class="text-xs font-semibold">v{{ version }}</span>
      </div>

      <div
        class="flex justify-center order-2 mt-10 sm:order-3 sm:justify-end sm:mt-0"
      >
        <div class="flex flex-col items-center">
          <select
            :value="locale"
            class="relative z-10 w-full px-4 py-1 bg-white border border-gray-700 focus:outline-none focus:shadow-outline"
            @change="changeLocale"
          >
            <option
              v-for="country of locales"
              :key="country.code"
              :value="country.code"
            >
              {{ $t(`otherslabels.${country.code}`) }}
            </option>
          </select>
          <a
            class="mt-2 hover:text-primary"
            href="https://yelo.agglo-larochelle.fr/faq/"
            rel="noopener"
            target="_blank"
          >
            FAQ
          </a>

          <p class="mt-4 text-center">{{ $t("pages.footer.label_socials") }}</p>
          <div class="flex justify-center mt-4">
            <a
              class="hover:text-primary"
              href="https://www.instagram.com/yelo_larochelle"
              rel="noopener"
              target="_blank"
            >
              <span class="sr-only">
                {{ $t("otherslabels.alt_instagram") }}
              </span>
              <IconInstagram class="h-6 mx-4 fill-current" />
            </a>
            <a
              class="hover:text-primary"
              href="https://www.facebook.com/larochelleagglo"
              rel="noopener"
              target="_blank"
            >
              <span class="sr-only">{{ $t("otherslabels.alt_facebook") }}</span>
              <IconFacebook class="h-6 mx-4 fill-current" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="postcss" scoped>
.footer {
  @screen sm {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: theme("spacing.6");
  }
}
</style>
