import { default as historyIR4l0mlf3lMeta } from "/app/pages/account/history.vue?macro=true";
import { default as invoicestmq8neHAodMeta } from "/app/pages/account/invoices.vue?macro=true";
import { default as notificationsyX8TYhCfvsMeta } from "/app/pages/account/notifications.vue?macro=true";
import { default as payment_45cardsRnR9jGJ0cbMeta } from "/app/pages/account/payment-cards.vue?macro=true";
import { default as personal_45detailsqywMAjslJnMeta } from "/app/pages/account/personal-details.vue?macro=true";
import { default as reservationsBCWnABNHx4Meta } from "/app/pages/account/reservations.vue?macro=true";
import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as resetZNqLl9CTHjMeta } from "/app/pages/auth/reset.vue?macro=true";
import { default as signupHJ1GDnyyMkMeta } from "/app/pages/auth/signup.vue?macro=true";
import { default as auth7tE1sZcZDUMeta } from "/app/pages/auth.vue?macro=true";
import { default as _91passengerId_93BpHIlsHCCNMeta } from "/app/pages/cards/duplicate/[passengerId].vue?macro=true";
import { default as _91passengerId_93BjvL7SmIjAMeta } from "/app/pages/cards/edit/[passengerId].vue?macro=true";
import { default as indexhEnu7n5u7IMeta } from "/app/pages/cards/index.vue?macro=true";
import { default as step11vvZLxrKQFMeta } from "/app/pages/cards/order/step1.vue?macro=true";
import { default as step2XFdgv7WJFwMeta } from "/app/pages/cards/order/step2.vue?macro=true";
import { default as step3PjZtXRTdNTMeta } from "/app/pages/cards/order/step3.vue?macro=true";
import { default as step43CnASA6NKvMeta } from "/app/pages/cards/order/step4.vue?macro=true";
import { default as step5utZretp7dVMeta } from "/app/pages/cards/order/step5.vue?macro=true";
import { default as step6DxcjZgbASCMeta } from "/app/pages/cards/order/step6.vue?macro=true";
import { default as orderSUGyv6RRjdMeta } from "/app/pages/cards/order.vue?macro=true";
import { default as _91id_93R3UJeYLik1Meta } from "/app/pages/cards/topup/[id].vue?macro=true";
import { default as cart9SktLKPpbzMeta } from "/app/pages/cart.vue?macro=true";
import { default as clear0ItD2BFdyuMeta } from "/app/pages/clear.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as pairingix1ZKpHdLWMeta } from "/app/pages/pairing.vue?macro=true";
export default [
  {
    name: "account___fr",
    path: "/account",
    component: () => import("/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-history___fr",
    path: "history",
    component: () => import("/app/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___fr",
    path: "invoices",
    component: () => import("/app/pages/account/invoices.vue").then(m => m.default || m)
  },
  {
    name: "account-notifications___fr",
    path: "notifications",
    component: () => import("/app/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-payment-cards___fr",
    path: "payment-cards",
    component: () => import("/app/pages/account/payment-cards.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-details___fr",
    path: "personal-details",
    component: () => import("/app/pages/account/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "account-reservations___fr",
    path: "reservations",
    component: () => import("/app/pages/account/reservations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/app/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-history___en",
    path: "history",
    component: () => import("/app/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: "account-invoices___en",
    path: "invoices",
    component: () => import("/app/pages/account/invoices.vue").then(m => m.default || m)
  },
  {
    name: "account-notifications___en",
    path: "notifications",
    component: () => import("/app/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: "account-payment-cards___en",
    path: "payment-cards",
    component: () => import("/app/pages/account/payment-cards.vue").then(m => m.default || m)
  },
  {
    name: "account-personal-details___en",
    path: "personal-details",
    component: () => import("/app/pages/account/personal-details.vue").then(m => m.default || m)
  },
  {
    name: "account-reservations___en",
    path: "reservations",
    component: () => import("/app/pages/account/reservations.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth___fr",
    path: "/auth",
    component: () => import("/app/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-login___fr",
    path: "login",
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___fr",
    path: "reset",
    component: () => import("/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___fr",
    path: "signup",
    component: () => import("/app/pages/auth/signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/app/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: "auth-login___en",
    path: "login",
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset___en",
    path: "reset",
    component: () => import("/app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup___en",
    path: "signup",
    component: () => import("/app/pages/auth/signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cards-duplicate-passengerId___fr",
    path: "/cards/duplicate/:passengerId()",
    component: () => import("/app/pages/cards/duplicate/[passengerId].vue").then(m => m.default || m)
  },
  {
    name: "cards-duplicate-passengerId___en",
    path: "/en/cards/duplicate/:passengerId()",
    component: () => import("/app/pages/cards/duplicate/[passengerId].vue").then(m => m.default || m)
  },
  {
    name: "cards-edit-passengerId___fr",
    path: "/cards/edit/:passengerId()",
    component: () => import("/app/pages/cards/edit/[passengerId].vue").then(m => m.default || m)
  },
  {
    name: "cards-edit-passengerId___en",
    path: "/en/cards/edit/:passengerId()",
    component: () => import("/app/pages/cards/edit/[passengerId].vue").then(m => m.default || m)
  },
  {
    name: "cards___fr",
    path: "/cards",
    meta: indexhEnu7n5u7IMeta || {},
    component: () => import("/app/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: "cards___en",
    path: "/en/cards",
    meta: indexhEnu7n5u7IMeta || {},
    component: () => import("/app/pages/cards/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-order___fr",
    path: "/cards/order",
    meta: orderSUGyv6RRjdMeta || {},
    component: () => import("/app/pages/cards/order.vue").then(m => m.default || m),
    children: [
  {
    name: "cards-order-step1___fr",
    path: "step1",
    component: () => import("/app/pages/cards/order/step1.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step2___fr",
    path: "step2",
    component: () => import("/app/pages/cards/order/step2.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step3___fr",
    path: "step3",
    component: () => import("/app/pages/cards/order/step3.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step4___fr",
    path: "step4",
    component: () => import("/app/pages/cards/order/step4.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step5___fr",
    path: "step5",
    component: () => import("/app/pages/cards/order/step5.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step6___fr",
    path: "step6",
    component: () => import("/app/pages/cards/order/step6.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cards-order___en",
    path: "/en/cards/order",
    meta: orderSUGyv6RRjdMeta || {},
    component: () => import("/app/pages/cards/order.vue").then(m => m.default || m),
    children: [
  {
    name: "cards-order-step1___en",
    path: "step1",
    component: () => import("/app/pages/cards/order/step1.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step2___en",
    path: "step2",
    component: () => import("/app/pages/cards/order/step2.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step3___en",
    path: "step3",
    component: () => import("/app/pages/cards/order/step3.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step4___en",
    path: "step4",
    component: () => import("/app/pages/cards/order/step4.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step5___en",
    path: "step5",
    component: () => import("/app/pages/cards/order/step5.vue").then(m => m.default || m)
  },
  {
    name: "cards-order-step6___en",
    path: "step6",
    component: () => import("/app/pages/cards/order/step6.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cards-topup-id___fr",
    path: "/cards/topup/:id()",
    component: () => import("/app/pages/cards/topup/[id].vue").then(m => m.default || m)
  },
  {
    name: "cards-topup-id___en",
    path: "/en/cards/topup/:id()",
    component: () => import("/app/pages/cards/topup/[id].vue").then(m => m.default || m)
  },
  {
    name: "cart___fr",
    path: "/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "clear___fr",
    path: "/clear",
    component: () => import("/app/pages/clear.vue").then(m => m.default || m)
  },
  {
    name: "clear___en",
    path: "/en/clear",
    component: () => import("/app/pages/clear.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pairing___fr",
    path: "/pairing",
    component: () => import("/app/pages/pairing.vue").then(m => m.default || m)
  },
  {
    name: "pairing___en",
    path: "/en/pairing",
    component: () => import("/app/pages/pairing.vue").then(m => m.default || m)
  }
]