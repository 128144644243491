import { defineStore } from "pinia";
import { type IAirwebTicketLegacyUserInfo } from "@airweb/ticket-legacy-client";

import { useCart } from "./cart";
import { useTunnel } from "./tunnel";

export const useAuth = defineStore("auth", {
  state: () => ({
    user: null as IAirwebTicketLegacyUserInfo | null,
  }),

  getters: {
    isLoggedIn: (state) => !!state.user,
    fullname: (state) => {
      if (!state.user) {
        return "";
      }

      return `${state.user.userName} ${state.user.userLastname}`;
    },
  },

  actions: {
    async logout() {
      const nuxtApp = useNuxtApp();

      const firebaseToken = useCookie("AW_FIREBASE_TOKEN", {
        expires: new Date("2030-01-01"),
      });
      const ticketToken = useCookie("AW_TICKET_TOKEN", {
        expires: new Date("2030-01-01"),
      });
      const cart = useCart();
      const tunnel = useTunnel();
      const router = useRouter();
      const localePath = useLocalePath();

      try {
        await nuxtApp.$ticket.account.logout(
          this.user?.userToken!,
          String(this.user?.userId!)
        );
      } catch {}

      firebaseToken.value = null;
      ticketToken.value = null;

      this.$reset();
      cart.$reset();
      tunnel.$reset();

      return router.push(localePath("auth-login"));
    },
  },
});
