import { get } from "idb-keyval";
import { defineStore } from "pinia";
import { type UploadedDocument } from "@/types/document";
import { type Product } from "@/types/products";

export const useTunnel = defineStore("tunnel", {
  state: () => ({
    product: null as Product | null,
    photo: null as UploadedDocument | null,
    lastUpdated: new Date().toString(),
    quantity: 1,
    personal: {
      awUserId: "",
      title: "",
      firstname: "",
      lastname: "",
      birthday: "",
      email: "",
      address: {
        line1: "",
        line2: "",
        postalCode: "",
        city: "",
        country: "France",
      },
    },
    delivery: {
      type: "SHIPPING" as "SHIPPING" | "COLLECT",
      firstname: "",
      lastname: "",
      address: {
        line1: "",
        line2: "",
        postalCode: "",
        city: "",
        country: "",
      },
    },
    documents: {} as Record<string, UploadedDocument | null>,
  }),

  async hydrate(state) {
    const renewFromState = await get<typeof state>("yelo-tunnel");

    const checkDate = new Date();
    checkDate.setDate(checkDate.getDate() - 1);

    if (
      !renewFromState?.lastUpdated ||
      new Date(renewFromState?.lastUpdated) < checkDate
    ) {
      state.product = null;
      state.quantity = 1;
      state.photo = null;
      state.personal = {
        awUserId: "",
        title: "",
        firstname: "",
        lastname: "",
        birthday: "",
        email: "",
        address: {
          line1: "",
          line2: "",
          postalCode: "",
          city: "",
          country: "France",
        },
      };
      state.documents = {};
      state.delivery = {
        type: "SHIPPING",
        firstname: "",
        lastname: "",
        address: {
          line1: "",
          line2: "",
          postalCode: "",
          city: "",
          country: "",
        },
      };
      return;
    }

    state.product = renewFromState?.product ?? null;
    state.quantity = renewFromState?.quantity ?? 1;
    state.photo = renewFromState?.photo ?? null;
    state.personal = renewFromState?.personal ?? {
      awUserId: "",
      title: "",
      firstname: "",
      lastname: "",
      birthday: "",
      email: "",
      address: {
        line1: "",
        line2: "",
        postalCode: "",
        city: "",
        country: "France",
      },
    };
    state.documents = renewFromState?.documents ?? {};
    state.delivery = renewFromState?.delivery ?? {
      type: "SHIPPING",
      firstname: "",
      lastname: "",
      address: {
        line1: "",
        line2: "",
        postalCode: "",
        city: "",
        country: "",
      },
    };
  },
});
