<template>
  <svg viewBox="0 0 50 50">
    <path
      d="M43.94 25.14a18.68 18.68 0 10-37.37 0h4.07a14.62 14.62 0 0129.23 0h4.07z"
    >
      <animateTransform
        attributeName="transform"
        attributeType="xml"
        dur="0.6s"
        from="0 25 25"
        repeatCount="indefinite"
        to="360 25 25"
        type="rotate"
      />
    </path>
  </svg>
</template>
