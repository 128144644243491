<script lang="ts" setup>
import { useAuth } from "@/stores/auth";
import { useCart } from "@/stores/cart";

const auth = useAuth();
const cart = useCart();
const localePath = useLocalePath();

async function logout() {
  return auth.logout();
}
</script>

<template>
  <header class="container mx-auto flex items-center p-4 sm:items-start">
    <NuxtLink :to="auth.isLoggedIn ? localePath('cards') : localePath('index')">
      <img
        alt="Logo Yélo"
        class="h-16 max-w-[initial] md:h-24"
        src="@/assets/images/logo_reseau.png"
      />
    </NuxtLink>

    <div
      v-if="auth.isLoggedIn"
      class="ml-4 flex w-full flex-col items-end py-4"
    >
      <div class="flex space-x-4">
        <NuxtLink
          :title="$t('pages.header.button_myaccount')"
          :to="localePath('account-personal-details')"
          class="ml-4 block text-right"
        >
          <span class="hidden sm:inline-block">
            {{ auth.fullname }}
          </span>
          <img
            alt="Profile Icon"
            class="h-6 sm:hidden"
            src="@/assets/images/header_icon_profil.svg"
          />
        </NuxtLink>

        <NuxtLink :to="localePath('cart')" class="relative">
          <span class="sr-only">Accéder au panier</span>

          <svg class="h-6 w-auto" viewBox="0 0 42 36.62">
            <g class="a" transform="translate(0 0.025)">
              <path
                d="M40.931,12.68h-6.9L22.075.492a1.076,1.076,0,0,0-1.84,0L8.278,12.68H1.15A1.086,1.086,0,0,0,0,13.83v6.439a1.086,1.086,0,0,0,1.15,1.15h.23L5.749,35.675a1.528,1.528,0,0,0,1.15.92H34.953c.46,0,1.15-.46,1.15-.92l4.369-14.257h.23a1.086,1.086,0,0,0,1.15-1.15V13.83C42.311,13.14,41.621,12.68,40.931,12.68ZM21.155,3.022l9.428,9.658H11.728ZM39.782,18.888h0c-.46,0-1.15.46-1.15.92L34.263,34.065H8.048L3.679,19.808a1.528,1.528,0,0,0-1.15-.92V14.979H39.782v3.909Z"
                transform="translate(0 0)"
              />
              <rect
                height="10.578"
                transform="translate(14.027 20.268)"
                width="2.529"
              />
              <rect
                height="10.578"
                transform="translate(20.006 20.268)"
                width="2.529"
              />
              <rect
                height="10.578"
                transform="translate(25.984 20.268)"
                width="2.529"
              />
            </g>
          </svg>

          <span
            v-if="cart.items.length > 0"
            class="absolute bottom-0 right-0 translate-x-2 translate-y-2 transform rounded-full bg-red-700 px-2 text-xs font-bold text-red-100"
          >
            {{ cart.items.length }}
          </span>
        </NuxtLink>

        <button class="sm:hidden" @click="logout">
          <svg class="block h-6 fill-current text-red-700" viewBox="0 0 24 24">
            <path
              d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z"
            />
          </svg>
        </button>
      </div>

      <nav class="mt-2 hidden sm:block">
        <ul class="flex font-bold">
          <li class="ml-4">
            <NuxtLink
              :to="localePath('cards')"
              activeClass="link--active"
              class="link relative"
            >
              {{ "Mes usagers" }}
            </NuxtLink>
          </li>
          <li class="ml-4">
            <NuxtLink
              :to="localePath('account-personal-details')"
              activeClass="link--active"
              class="link relative"
            >
              {{ $t("pages.header.button_myaccount") }}
            </NuxtLink>
          </li>
          <li class="ml-4">
            <button
              class="link link--danger relative font-bold text-red-700"
              type="button"
              @click="logout"
            >
              {{ $t("pages.header.button_logout") }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<style lang="postcss" scoped>
.link::before {
  @apply absolute left-0 h-1 w-full rounded bg-primary;
  content: "";
  top: 100%;
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
  transform-origin: center right;
}

.link--danger::before {
  @apply bg-red-700;
}

.link:hover::before,
.link--active::before {
  transform: scaleX(1);
  transform-origin: center left;
}
</style>