export function deepToRaw<T extends Record<string, unknown>>(sourceObj: T): T {
  const objectIterator = <U extends Record<string, unknown>>(
    input: U,
  ): U[keyof U] => {
    const isArray = Array.isArray(input);
    const isRefOrReactiveOrProxy =
      isRef(input) || isReactive(input) || isProxy(input);
    const isObject = input && typeof input === "object";
    const isNotFile = !(input instanceof File);

    if (isArray) {
      return input.map((item) => objectIterator(item)) as U[keyof U];
    }

    if (isRefOrReactiveOrProxy) {
      return objectIterator(toRaw(input)) as U[keyof U];
    }

    if (isObject && isNotFile) {
      return Object.keys(input).reduce(
        (acc, key) => {
          const validKey = key as keyof U;
          // @ts-expect-error Not sure why ts isn't happy here
          acc[validKey] = objectIterator(input[validKey]) as U[keyof U];
          return acc;
        },
        {} as U[keyof U],
      ) as U[keyof U];
    }

    return input as U[keyof U];
  };

  return objectIterator(sourceObj) as T;
}
