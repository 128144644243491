import { get } from "idb-keyval";
import { defineStore } from "pinia";
import { type Product } from "@/types/products";
import { type OrderType } from "@/types/orders";
import { type Passenger } from "@/types/passenger";
import { type UploadedDocument } from "@/types/document";
import { v4 as uuidv4 } from "uuid";

interface CartProduct extends Product {
  ubiProductId?: string;
}

interface Address {
  type: "SHIPPING" | "COLLECT";
  firstname?: string;
  lastname?: string;
  address: {
    line1?: string;
    line2?: string;
    postalCode?: string;
    city?: string;
    country?: string;
  };
}

export interface CartItem {
  id: string;
  type: "CARD_RELOAD" | "CARD_DUPLICATE" | "CARD_CREATION";
  product: CartProduct;
  quantity: number;
  awUserId?: string;
  ubiUserId?: string;
  personal?: {
    title: string;
    firstname: string;
    lastname: string;
    birthday: string;
    email: string;
    address: {
      line1: string;
      line2: string;
      postalCode: string;
      city: string;
      country: string;
    };
    awUserId?: string;
  };
  card?: string;
  photo?: UploadedDocument | null;
  documents?: Record<string, UploadedDocument | null>;
  // This is useful ONLY to display the card on the cart
  passenger?: Passenger;
}

export const useCart = defineStore("cart", {
  state: () => ({
    items: [] as CartItem[],
    orderType: "" as OrderType,
    passengerId: "",
    lastUpdated: new Date().toString(),
    delivery: {
      type: "SHIPPING" as "SHIPPING" | "COLLECT",
      firstname: "",
      lastname: "",
      address: {
        line1: "",
        line2: "",
        postalCode: "",
        city: "",
        country: "",
      },
    } as Address | null,
  }),

  getters: {
    total(state): number {
      return state.items.reduce((acc, item) => {
        return (
          acc + parseFloat(item.product.shopItemPromoPrice) * item.quantity
        );
      }, 0);
    },
    installments(state): number[] {
      // STEP1: Compute all unique installments for every items in the cart
      const itemsInstallements = state.items.reduce<number[]>(
        (installments, { product }) => {
          return [
            ...new Set([...installments, ...(product.installments ?? [])]),
          ];
        },
        []
      );

      // STEP3: Filter out progressively the installments not supported by all product
      const filteredInstallments = state.items.reduce<number[]>(
        (installments, { product }) => {
          return installments.filter(
            (i) => product.installments.includes(i) ?? false
          );
        },
        itemsInstallements
      );

      // STEP4: Make sure there's at least a 1 time payment available
      return filteredInstallments.includes(1)
        ? filteredInstallments
        : [1, ...filteredInstallments];
    },
    hasReloads(state): boolean {
      return state.items.some((item) => item.type === "CARD_RELOAD");
    },
    hasPostPayment(state): boolean {
      const config = useRuntimeConfig();

      return state.items.some(
        (item) => item.product.ubiProductId === config.public.postPaymentFareId
      );
    },
  },

  actions: {
    addItem(item: Omit<CartItem, "id">) {
      const cartIndex = this.items.findIndex((cartItem) => {
        const isSamePassenger =
          cartItem.awUserId === item.awUserId ||
          cartItem.ubiUserId === item.ubiUserId;

        const isSameProduct =
          cartItem.product?.shopItemId === item.product?.shopItemId;

        return isSamePassenger && isSameProduct;
      });

      if (cartIndex !== -1) {
        this.items[cartIndex].quantity += item.quantity;
        return;
      }

      const uuid = uuidv4();

      this.items.push({
        id: uuid,
        ...item,
      });
    },
    removeItem(itemId: string) {
      this.items = this.items.filter((item) => item.id !== itemId);
    },
  },

  async hydrate(state) {
    const renewFromState = await get<typeof state>("yelo-cart");

    const checkDate = new Date();
    checkDate.setDate(checkDate.getDate() - 1);

    if (
      !renewFromState?.lastUpdated ||
      new Date(renewFromState?.lastUpdated) < checkDate
    ) {
      state.items = [];
      state.passengerId = "";
      state.orderType = "CARD_CREATION";
      state.delivery = null;
      state.lastUpdated = new Date().toISOString();
      return;
    }

    state.items = renewFromState?.items ?? [];
    state.passengerId = renewFromState?.passengerId ?? "";
    state.orderType = renewFromState?.orderType ?? "CARD_CREATION";
    state.delivery = renewFromState?.delivery ?? null;
    state.lastUpdated = renewFromState?.lastUpdated ?? new Date().toISOString();
  },
});
